import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

// Widget is taken from >>> https://businessapp.b2b.trustpilot.com/trustbox/5419b732fbfb950b10de65e5

const MicroStarWidget = ({ locale }) => {
    const ref = useRef(null)

    // eslint-disable-next-line max-len
    // SPA needs to load trust pilot widgets manually, see >>> https://support.trustpilot.com/hc/en-us/articles/115011421468--Add-a-TrustBox-widget-to-a-single-page-application
    useEffect(() => {
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true)
        }
    }, [])

    return (
        <div
            ref={ref}
            className="trustpilot-widget"
            data-locale={locale}
            data-template-id="5419b732fbfb950b10de65e5"
            data-businessunit-id="5df8af54b30f2300017c5da7"
            data-style-height="24px"
            data-style-width="100%"
            data-theme="light"
        >
            <a
                href="https://www.trustpilot.com/review/www.gymondo.com"
                target="_blank"
                rel="noopener noreferrer"
            >
                Trustpilot
            </a>
        </div>
    )
}

MicroStarWidget.propTypes = {
    locale: PropTypes.oneOf(['en', 'de']).isRequired,
}

export default MicroStarWidget
