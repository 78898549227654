import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import MicroStarWidget from './components/micro-star-widget'
import MicroComboWidget from './components/micro-combo-widget'
import style from './trust-pilot-link.module.scss'

export const TrustPilotLink = ({ locale }) => {
    const { hasAnalyticsConsent } = useSelector((state) => state.cookieConsent)
    if (!hasAnalyticsConsent) {
        return null
    }

    return (
        <section className={style.wrapper}>
            <Helmet>
                <script
                    type="text/javascript"
                    src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
                    async
                />
            </Helmet>
            <div className={style.desktopWidget}>
                <MicroStarWidget locale={locale} />
            </div>
            <div className={style.mobileWidget}>
                <MicroComboWidget locale={locale} />
            </div>
        </section>
    )
}

TrustPilotLink.propTypes = {
    locale: PropTypes.oneOf(['en', 'de']).isRequired,
}

export default TrustPilotLink
