import React, { useState, useLayoutEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import moment from 'moment'
import { Tracker } from '@gymondo/frontend-core/utils'
import jsCookie from 'js-cookie'
import cn from 'classnames'
import { COOKIE_NAMES } from '@gymondo/frontend-core/enums'

import useInterval from '../../../hook/use-interval/use-interval.hook'

import style from './campaign-banner.module.scss'
import { getCurrentCampaign } from '../../../api/service/campaign/campaign.service'

import { getTrialTitle } from '../../../util/trial-interval.util'

import { LinkLocalized } from '../..'

const renderTimeUnit = (value, formattedMessageId) => (
    <div className={style.countdown}>
        <div className={style.countdownValue}>{value}</div>
        <div className={style.countdownUnit}>
            <FormattedMessage
                values={{ count: value }}
                id={formattedMessageId}
            />
        </div>
    </div>
)

// eslint-disable-next-line react/prop-types
const renderContent = (title, text, className) => (
    <div className={className}>
        {title} {text}
    </div>
)

const getRemainingTime = (campaign) => {
    const campaignEnd = moment.utc(campaign.end).subtract(1, 'second')

    return moment.utc(campaignEnd.diff(moment()))
}

export const CampaignBanner = ({
    isExistingUser,
    isLoggedIn,
    hasAnalyticsConsent,
}) => {
    const intl = useIntl()
    const [campaign, setCampaign] = useState(null)
    const [date, setDate] = useState(null)

    const ranOut = date && date.valueOf() <= 0
    const region = jsCookie.get(COOKIE_NAMES.USER_REGION)
    const DISPLAY_COUNTDOWN_REGIONS = ['DE', 'AT', 'CH']
    const hasCountdown = DISPLAY_COUNTDOWN_REGIONS.includes(region)

    useInterval(
        () => {
            if (!isExistingUser && !isLoggedIn && campaign) {
                const remainingTime = getRemainingTime(campaign)
                setDate(remainingTime)
            }
        },
        ranOut ? null : 1000
    )

    useLayoutEffect(() => {
        ;(async () => {
            if (!isExistingUser && !isLoggedIn) {
                const currentCampaign = await getCurrentCampaign().catch(
                    () => null
                )
                if (currentCampaign) {
                    setCampaign(currentCampaign)
                    setDate(getRemainingTime(currentCampaign))
                }
            }
        })()
    }, [isExistingUser, isLoggedIn, intl.locale, region, hasAnalyticsConsent])

    if (ranOut) {
        return null
    }

    if (date && campaign && !isExistingUser && !isLoggedIn) {
        const banner = (
            <div className={cn(style.wrapp, style.plus)}>
                <div className={hasCountdown && style.content}>
                    {renderContent(
                        campaign.desktopTitle,
                        campaign.desktopText,
                        style.desktopContent
                    )}
                    {renderContent(
                        campaign.mobileTitle,
                        campaign.mobileText,
                        style.mobileContent
                    )}
                </div>
                {hasCountdown && campaign.countDown !== false && (
                    <>
                        {renderTimeUnit(
                            date.hour(),
                            'campaignBanner.hourWithoutValue'
                        )}
                        {renderTimeUnit(
                            date.minute(),
                            'campaignBanner.minuteWithoutValue'
                        )}
                        {renderTimeUnit(
                            date.second(),
                            'campaignBanner.secondWithoutValue'
                        )}
                    </>
                )}
            </div>
        )

        if (campaign.externalLink) {
            return (
                <a
                    href={campaign.externalLink}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {banner}
                </a>
            )
        }

        return (
            <LinkLocalized
                to="checkout"
                title={getTrialTitle(intl)}
                data-evt={Tracker.EVENT_TYPES.CLICK}
                data-category="global"
                data-action="click_register_cta"
                data-label="register_campaign"
            >
                {banner}
            </LinkLocalized>
        )
    }

    return null
}

CampaignBanner.propTypes = {
    isExistingUser: PropTypes.bool.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    hasAnalyticsConsent: PropTypes.bool.isRequired,
}

const mapStateToProps = ({ user, cookieConsent }) => ({
    ...user,
    ...cookieConsent,
})

export default connect(mapStateToProps)(CampaignBanner)
